$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`

$prefix: $variable-prefix !default;

// Clarendon BT Pro Black
// Clarendon Std Light Narrow
// Vatik ExtraBlack
// Nimbus Sans Std T Italic
// Nimbus Sans Std Bold

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: nimbus-sans, system-ui, -apple-system, "Segoe UI",
  Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
$font-family-serif: clarendon-urw, "Clarendon", "Baskerville",
  "Baskerville Old Face", "Hoefler Text", "Times New Roman", "serif";

// stylelint-enable value-keyword-case
// $font-family-base: var(--#{$prefix}font-sans-serif) !default;
$font-family-base: var(--#{$prefix}font-serif) !default;
$font-family-code: var(--#{$prefix}font-monospace) !default;

:root {
  --#{$prefix}font-serif: #{inspect($font-family-serif)};
  --#{$prefix}body-font-family: #{inspect($font-family-base)};
}

// scss-docs-start headings-variables
// $headings-margin-bottom: $spacer * 0.5 !default;
// $headings-font-family: null !default;
// $headings-font-style: null !default;
// $headings-font-weight: 500 !default;
// $headings-line-height: 1.2 !default;
// $headings-color: inherit !default;

// $headings-font-family: var(--#{$prefix}font-serif) !default;
$headings-font-family: $font-family-serif !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-light !default;

$vanillaCream: #f8f2d8;
$vanillaLight: shift-color($vanillaCream, 10%);
$vanillaMedium: shift-color($vanillaCream, 20%);
$vanillaDark: shift-color($vanillaCream, 40%);
$rustyOrange: #c0782a;
$terraCotta: #8f4a3f;
$deepPurple: #412735;
$charcoalBlack: #1f2222;
$brightYellow: #ffd700;

$body-bg: $vanillaCream;
$body-color: $charcoalBlack;
$primary: $deepPurple;
$secondary: $terraCotta;

// Font Clarendon URW ?

// $nav-tabs-border-color: var(--#{$prefix}border-color);
// $nav-tabs-border-width: var(--#{$prefix}border-width);
// $nav-tabs-border-radius: var(--#{$prefix}border-radius);
// $nav-tabs-link-hover-border-color: var(--#{$prefix}secondary-bg)
//   var(--#{$prefix}secondary-bg) $nav-tabs-border-color;
// $nav-tabs-link-active-color: var(--#{$prefix}emphasis-color);
// $nav-tabs-link-active-bg: var(--#{$prefix}body-bg);
// $nav-tabs-link-active-border-color: var(--#{$prefix}border-color)
//   var(--#{$prefix}border-color) $nav-tabs-link-active-bg;

$nav-tabs-border-color: $rustyOrange;
// $nav-tabs-border-width: var(--#{$prefix}border-width);
// $nav-tabs-border-radius: var(--#{$prefix}border-radius);
$nav-tabs-link-hover-border-color: $rustyOrange $rustyOrange $rustyOrange;
$nav-tabs-link-active-color: "white";
$nav-tabs-link-active-bg: $terraCotta;
$nav-tabs-link-active-border-color: $terraCotta $terraCotta $terraCotta;

$dropdown-color: "white";
$dropdown-bg: $deepPurple;
$dropdown-link-color: shift-color(tint-color($primary, 80%), 20%) !default;
// $dropdown-link-hover-color: shift-color(
//   $dropdown-link-color,
//   -$link-shade-percentage
// ) !default;
$dropdown-link-hover-color: shift-color($dropdown-link-color, -60%) !default;
$dropdown-link-hover-bg: $charcoalBlack;

$pagination-bg: $vanillaLight;
$pagination-border-color: $primary;

$pagination-hover-bg: $vanillaMedium;
$pagination-hover-border-color: $primary;

$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;

$pagination-disabled-bg: $vanillaDark;
// $pagination-disabled-border-color: $vanillaCream;

// $pagination-focus-color:            var(--#{$prefix}link-hover-color);
$pagination-focus-bg: shift-color($vanillaCream, -10%);
// $pagination-focus-box-shadow:       $focus-ring-box-shadow;
// $pagination-focus-outline:          0;

$border-color: $charcoalBlack;
