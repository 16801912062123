@use "sass:map";
.page-container {
  min-height: 100dvh;
}

.align-right {
  text-align: right;
}

.highlight {
  background-color: shift-color($rustyOrange, -20%);
  padding: 0 4px;
}

.navbar {
  font-family: $font-family-serif;
}

footer.site-footer {
  background-color: $rustyOrange;
}

.floral {
  //add a background image repeat along x axis from file floral.png
  height: 33px;
  background: $body-bg url("../images/floral.png") repeat-x !important;
  background-size: 152px 25px !important;
  background-position-y: center !important;
  border-bottom: 2px solid $primary;

  // background-color: $primary;
}

ul.flower-list {
  li {
    list-style: none;
    background-image: url("../images/flower.png");
    background-size: 25px 18px;
    background-repeat: no-repeat;
    background-position: 0 6px;
    padding-left: 2rem;
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
  }
}

.letter-alert {
  // color: $body-bg;
  background-color: $brightYellow;
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  top: -24px;
}

.letter-alert-inline {
  background-color: $brightYellow;
}

$scrollbar-color: $primary;
$scrollbar-width: 0.375rem;
$scrollbar-border-radius: 0.25rem;

.custom-scrollbar {
  scrollbar-width: thin; // For Firefox
  padding-right: $scrollbar-width;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
    height: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: $scrollbar-border-radius;
  }

  // For Firefox
  scrollbar-color: $scrollbar-color transparent;
}

.zoom-pan-pinch-controlPanel {
  position: absolute;
  z-index: 2;
  transform: translate(10px, 10px);
  max-width: calc(100% - 20px);
}
.zoom-pan-pinch-controlBtn {
  color: $charcoalBlack;
  padding: 3px 6;
  background: white;
  border: 1px solid $charcoalBlack;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    font-size: 0.75rem;
    margin-right: 10px;
    padding: 6px 12px;
  }
}

.zoom-pan-pinch-controlBtn:focus {
  filter: brightness(90%);
}

.zoom-pan-pinch-controlBtn:hover {
  filter: brightness(120%);
}

.zoom-pan-pinch-controlBtn:active {
  opacity: 0.9;
}

.zoom-pan-pinch-grid {
  display: grid;
  grid-template-columns: repeat(100, 1fr);
}

#letter-nav {
  scroll-margin: calc(90px);
}

.letter-wrapper {
  .letter-image {
    height: 400px;
    height: calc(70dvh - 90px);
    width: 272px;
    // @include media-breakpoint-only(xs) {  width: 200px; }

    @media (min-width: 375px) {
      width: 327px;
    }

    @media (min-width: 390px) {
      width: 342px;
    }

    @media (min-width: 414px) {
      width: 366px;
    }

    @media (min-width: 430px) {
      width: 382px;
    }

    @media (min-width: 480px) {
      width: 432px;
    }

    @media (min-width: 520px) {
      width: 472px;
    }

    @media (min-width: 560px) {
      width: 512px;
    }

    @include media-breakpoint-only(sm) {
      width: map.get($container-max-widths, "sm");
    }
    @include media-breakpoint-only(md) {
      width: map.get($container-max-widths, "md");
    }
    @include media-breakpoint-only(lg) {
      width: map.get($container-max-widths, "lg");
    }
    @include media-breakpoint-only(xl) {
      width: map.get($container-max-widths, "xl");
    }
    @include media-breakpoint-only(xxl) {
      width: map.get($container-max-widths, "xxl");
    }

    object-fit: contain;
  }
}

.navbar-brand {
  font-size: 1rem;
  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
  }
}

.fs-sm {
  // font-size: 1rem !important;

  @include media-breakpoint-down(md) {
    font-size: 1rem !important;
  }
}

.btn-view-letter {
  font-size: 0.625rem;

  @include media-breakpoint-up(md) {
    font-size: 0.875rem;
  }
}

.letter-list-item {
  font-size: 0.75rem;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}
